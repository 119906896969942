/* eslint-disable @typescript-eslint/no-explicit-any */
import { Adress } from './BillDetailWDH'
import { Prestation } from './myDemands'

export default function formatAdresse(adress: Adress | undefined): string {
  let formatedAddress = ''
  if (adress !== undefined) {
    const { rueAdresse, villeAdresse, codePostalAdresse } = adress
    formatedAddress += `${rueAdresse || ''} ${codePostalAdresse || ''} ${
      villeAdresse || ''
    }`
  }
  return formatedAddress
}
export function initAdressCollectPoint(item: any): string {
  const preparedAdress = item.adresseSiteClientRue || ''
  const adresseSiteClientNum = item.adresseSiteClientNom || ''

  return `${adresseSiteClientNum} ${preparedAdress}  ${item.adresseSiteClientCP}  ${item.adresseSiteClientVille} `
}

export function groupedAdresses(adresses: Prestation[]) {
  const groupedAdresses: { [key: string]: string[] } = adresses
    .filter((value, index, self) => {
      return self.map((x) => x.idAdresse).indexOf(value.idAdresse) === index
    })
    .reduce((acc: any, adresse) => {
      const key = `${adresse.nomAdresse};;${adresse.rueAdresse};;${adresse.codePostalAdresse};;${adresse.villeAdresse};;${adresse.fkentreprise.idEntreprise};;${adresse.fkentreprise.raisonSociale}`
      if (!acc[key]) {
        acc[key] = []
      }
      acc[key].push(adresse.idAdresse)
      return acc
    }, {})

  return Object.entries(groupedAdresses).map(([key, ids]) => {
    const [
      nomAdresse,
      rueAdresse,
      codePostalAdresse,
      villeAdresse,
      idEntreprise,
      raisonSociale,
    ] = key.split(';;')

    return {
      nomAdresse,
      rueAdresse,
      codePostalAdresse,
      villeAdresse,
      idEntreprise,
      raisonSociale,
      ids,
    }
  })
}
