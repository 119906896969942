/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable no-await-in-loop */
import { Dispatch } from 'react'
import axios, { CancelTokenSource } from 'axios'
import moment from 'moment'
import qs from 'qs'
// eslint-disable-next-line
import jwt_decode from 'jwt-decode'
import * as Msal from 'msal'
import Cookies from 'universal-cookie'

import OrganizationEntity, {
  FormatedOrganization,
} from '../utils/OrganizationEntity'
import AllRolesEntity from '../utils/AllRolesEntity'
import CollapseDto from '../utils/CollapseDto'
import {
  ContactPreference,
  DEFAULT_USERINFOS,
  StatusOptions,
  UserEntity,
} from '../utils/UserEntity'
import UsersNormalizer from '../normalizer/UsersNormaliser'
import { getMemberData, getNextPage, isLastPage } from './hydra-services'
import InstitutionEntity from '../utils/InstitutionEntity'

import { InitialStateType } from '../store/state'
import { APP_ACTIONS, ActionTypes } from '../store/actions'
import ROUTES from '../utils/ROUTES'
import { getFilterForId } from '../utils/BillEntity'
import {
  getFormatedOrganisations,
  searchOrganizationByIds,
} from './organization-services'
import { isCancel } from './billing-services'
import { getAdminUserSymbol, getStatusValue } from '../utils/user-utils'
import { getDomainMail } from '../utils/ts-utils'
import { getAllRoles } from './roles-services'
import { flatFilter } from '../utils/filter-utils'

const XlsxTemplate = require('xlsx-template')

const msalConfig = {
  auth: {
    clientId: `${process.env.REACT_APP_AZURE_AD_CLIENT_ID}`,
    authority: `${process.env.REACT_APP_AZURE_AD_URL_AUTH}`,
    redirectUri: `${process.env.REACT_APP_AZURE_AD_REDIRECT_URI}`,
  },
}

const msalInstance = new Msal.UserAgentApplication(msalConfig)

let cancelToken: CancelTokenSource

export interface DecodedToken {
  username: string
  // eslint-disable-next-line camelcase
  user_id: string
  organizations: OrganizationToken[]
  roles: any[]
  // eslint-disable-next-line camelcase
  first_name: string
  access_demands: number
  access_docs: number
  isInternal: number
}

export interface UserData {
  username: string
  userId: string
  organizations: string[]
  token?: string
  roleNames: string[]
  firstname: string
  accessDemands: boolean
  accessDocs: boolean
  isInternal: boolean
}

interface OrganizationToken {
  id: string
}

const labelsToApiFields: { [key: string]: string } = {
  Nom: 'lastname',
  Prénom: 'firstname',
  Identifiant: 'username',
  'Éts de rattachement': 'company',
  'Créé le': 'createdAt',
  'Dernière connexion': 'lastConnexionDate',
  Statut: 'state',
}

export async function getUserInfo(userId: string): Promise<UserEntity> {
  const url = `${process.env.REACT_APP_BASE_API_URL}/users/${userId}`
  let user: UserEntity = DEFAULT_USERINFOS

  try {
    const resp = await axios({
      method: 'GET',
      url,
    })
    if (resp?.data) {
      const result = UsersNormalizer.normalizeSingle([resp.data])

      if (result.length > 0) {
        const [item] = result
        user = item
      }
    }
  } catch (err) {
    throw new Error(
      `Erreur lors de l'appel de l'api getUserInfo avec l'url : '/users/${userId}'`
    )
  }

  return user
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function createUser(user: any): Promise<string> {
  const url = `${process.env.REACT_APP_BASE_API_URL}/users`

  let status = ''

  try {
    const resp = await axios({
      method: 'POST',
      url,
      data: {
        username: user.username,
        type: user.type,
        email: user.email,
        firstname: user.firstname,
        lastname: user.lastname,
        fonction: user.fonction,
        officePhone: user.officePhone,
        mobilePhone: user.mobilePhone,
        compagny: user.compagny,
        compagnyId: user.compagnyId,
        preferences: user.preferences,
        roles: user.roles,
        accessDemands: user.accessDemands,
        accessDocs: user.accessDocs,
      },
    })

    if (resp?.data['@id'] !== undefined) {
      status = resp?.data['@id']
    }
  } catch (err) {
    if (err?.response?.data?.violations) {
      throw new Error(err.response.data.violations[0].message)
    }
    throw new Error("Erreur lors de la création de l'utilisateur")
  }

  return status
}

export async function patchUserForCompagny(
  company: string,
  companyId: string,
  userId: string,
  history: any
): Promise<number> {
  const url = `${process.env.REACT_APP_BASE_API_URL}/users/${userId}`

  let status = 400

  try {
    const resp = await axios({
      method: 'PATCH',
      url,
      data: {
        company,
        companyId,
      },
    })
    status = resp?.status
  } catch (err) {
    history.push(`${ROUTES.TechnicalError}`)
  }

  return status
}

export async function patchUserForCompagnyModif(
  company: string,
  companyId: string,
  userId: string
): Promise<number> {
  const url = `${process.env.REACT_APP_BASE_API_URL}/users/${userId}`

  let status = 400

  try {
    const resp = await axios({
      method: 'PATCH',
      url,
      data: {
        company,
        companyId,
      },
    })
    status = resp?.status
  } catch (err) {
    throw new Error(
      `Erreur lors de l'exécution de l'api patchUserForCompagnyModif`
    )
  }

  return status
}

export async function patchUser(user: UserEntity): Promise<number> {
  const url = `${process.env.REACT_APP_BASE_API_URL}/users/${user.id}`
  let status = 400

  try {
    const resp = await axios({
      method: 'PATCH',
      url,
      data: { ...user },
    })
    status = resp?.status
  } catch (err) {
    if (err?.response?.data?.violations) {
      throw new Error(err.response.data.violations[0].message)
    }
    throw new Error("Une erreur est survenue lors de l'enregistrement")
  }

  return status
}

export function prepareDataForPatch(
  user: UserEntity,
  userId: string,
  roleUris: string[],
  pref: ContactPreference
): any {
  const userBody: any = user
  delete userBody.createdAt
  delete userBody.lastConnexionDate
  delete userBody.passwordExpirationDate
  delete userBody.organizations
  delete userBody.companyId
  delete userBody.company
  userBody.id = userId
  userBody.preferences = JSON.stringify(pref)
  userBody.roles = roleUris

  return userBody
}

export async function getUserIdByUserName(username: string): Promise<string> {
  const url = `${process.env.REACT_APP_BASE_API_URL}/users/username=${username}`
  try {
    const resp = await axios({
      method: 'GET',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      url,
    })
    if (resp?.data) {
      const result = UsersNormalizer.normalize([resp.data])

      if (result.length > 0) {
        const [item] = result
        return item.id
      }
    }
  } catch (err) {
    console.error('cannot get UserId')
  }

  return ''
}

export async function getUserInfoFromAD(UpnId: string): Promise<any> {
  const url = `${process.env.REACT_APP_BASE_API_URL}/users/aad/${UpnId}`

  let userUpn
  try {
    const resp = await axios({
      method: 'GET',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      url,
    })
    userUpn = resp?.data
  } catch (err) {
    return null
  }
  return userUpn
}

export function prepareFinalDtoData(
  allDto: CollapseDto[] | undefined,
  selectedList: string[]
): CollapseDto[] {
  const result: CollapseDto[] = []
  if (allDto)
    allDto.forEach((dto) => {
      if (selectedList.includes(dto.id)) result.push(dto)
      if (dto.children !== undefined) {
        dto.children.forEach((child) => {
          if (child.type === 'collapse') {
            if (selectedList.includes(child.id)) result.push(child)
            prepareFinalDtoData(dto.children, selectedList)
            const recResult = prepareFinalDtoData(dto.children, selectedList)
            if (recResult) {
              recResult.forEach((elem) => {
                result.push(elem)
              })
            }
          }
        })
      }
    })
  return result.filter((v, i, a) => a.findIndex((xt) => xt.id === v.id) === i)
}
/**
 * When selecting  an organization get the ids of
 * parent and children to hide checkbox
 * @param allDto
 * @param selectedList
 */
export function getCollapseIdsToHideCheckbox(
  allDto: CollapseDto[] | undefined,
  selectedList: string[]
): string[] {
  const result: string[] = []
  if (allDto)
    allDto.forEach((dto) => {
      if (dto.parentId && selectedList.includes(dto.id))
        result.push(dto.parentId)
      if (dto.children !== undefined) {
        dto.children.forEach((child) => {
          if (child.type === 'collapse') {
            if (child.parentId && selectedList.includes(child.parentId)) {
              result.push(child.id)
            }
            const recResult = getCollapseIdsToHideCheckbox(
              dto.children,
              selectedList
            )
            if (recResult)
              recResult.forEach((elem) => {
                result.push(elem)
              })
          }
        })
      }
    })
  return result.filter(function (item, pos) {
    return result.indexOf(item) === pos
  })
}

export async function patchUserForPerimetreOrganisations(
  organizations: FormatedOrganization[],
  userId: string,
  history?: any
): Promise<number> {
  const url = `${process.env.REACT_APP_BASE_API_URL}/users/${userId}`

  let status = 400

  try {
    const resp = await axios({
      method: 'PATCH',
      url,
      data: {
        organizations,
      },
    })
    status = resp?.status
  } catch (err) {
    if (history) history.push(ROUTES.TechnicalError)
    console.error(err)
  }
  return status
}

export async function deleteOrganisationsForUser(
  organizations: FormatedOrganization[],
  userId: string,
  history?: any
): Promise<string[]> {
  return Promise.all(
    organizations.map(async (element) => {
      const appId = element.application.split('/')[2]
      const url = `${process.env.REACT_APP_BASE_API_URL}/asso_users_organisations/organisationId=${element.organisationId};application=${appId};user=${userId}`

      try {
        const res = await axios({
          method: 'DELETE',
          url,
          headers: {
            'content-type': 'application/merge-patch+json',
          },
        })
        if (res?.status === 204) return element.organisationId
        return ''
      } catch (error) {
        if (history) history.push(ROUTES.TechnicalError)
        console.error(error)
        return ''
      }
    })
  )
}

export async function patchUserForActivation(
  state: string,
  userId: string,
  history?: any
): Promise<number> {
  const url = `${process.env.REACT_APP_BASE_API_URL}/users/${userId}`

  let status = 400

  try {
    const resp = await axios({
      method: 'PATCH',
      url,
      data: {
        state,
        userId,
      },
    })
    status = resp?.status
  } catch (err) {
    if (history) history.push(ROUTES.TechnicalError)
    console.error(err)
  }
  return status
}

// GESTION DES UTILISATEUR

// get all the users using the api
export async function getAllUsers(
  perPage: any,
  page: number,
  organisation: OrganizationEntity[] = [],
  institution: InstitutionEntity[] = [],
  sortTable: any,
  filters: any[]
): Promise<{ results: UserEntity[]; pageCount: number }> {
  const url = `${process.env.REACT_APP_BASE_API_URL}/users?perPage=${perPage}&page=${page}`

  let listOfusers: UserEntity[] = []
  const params = new URLSearchParams()

  organisation.forEach((org) => {
    params.append('idOrganisation[]', org.id)
  })
  institution.forEach((insti) => {
    params.append('companyId[]', insti.id)
  })
  if (sortTable) {
    Object.keys(sortTable).forEach((key: string) => {
      params.append(`order[${labelsToApiFields[key]}]`, sortTable[key])
    })
  }
  const lastname = getFilterForId(filters, 'lastname')
  if (lastname) {
    params.append('lastname', lastname)
  }
  const firstname = getFilterForId(filters, 'firstname')
  if (firstname) {
    params.append('firstname', firstname)
  }
  const username = getFilterForId(filters, 'username')
  if (username) {
    params.append('username', username)
  }
  const companyFilter = getFilterForId(filters, 'companyId')
  if (companyFilter) {
    params.append('searchCompany', companyFilter)
  }
  const statusFilter = getFilterForId(filters, 'state')
  if (statusFilter && statusFilter.value !== 'all') {
    params.append('state[]', statusFilter.value)
  }
  if (getFilterForId(filters, 'createdAt')) {
    const [startDate, endDate] = getFilterForId(filters, 'createdAt')
    params.append('createdAt[after]', moment(startDate).format('YYYY-MM-DD'))
    params.append('createdAt[before]', moment(endDate).format('YYYY-MM-DD'))
  }
  if (getFilterForId(filters, 'lastConnexionDate')) {
    const [startDate, endDate] = getFilterForId(filters, 'lastConnexionDate')
    params.append(
      'lastConnexionDate[after]',
      moment(startDate).format('YYYY-MM-DD')
    )
    params.append(
      'lastConnexionDate[before]',
      moment(endDate).format('YYYY-MM-DD')
    )
  }

  if (cancelToken !== undefined) {
    cancelToken.cancel('Operation canceled due to new request.')
  }

  cancelToken = axios.CancelToken.source()

  try {
    const resp = await axios({
      method: 'GET',
      url,
      params,
      cancelToken: cancelToken.token,
    })
    if (resp?.data) {
      // Result Normalization
      listOfusers = UsersNormalizer.normalize(getMemberData(resp.data))
      const totalItems = parseInt(resp.data['hydra:totalItems'], 10)
      return {
        results: [...listOfusers],
        pageCount: Math.ceil(totalItems / perPage),
      }
    }
  } catch (err) {
    if (isCancel(err)) {
      return Promise.resolve({ pageCount: 0, results: err })
    }
    throw new Error(`Erreur : ${err}`)
  }
  return Promise.resolve({ pageCount: 0, results: [] })
}

// get all the users using the api
export async function getAllUsersSuperAdmin(
  perPage: any,
  page: number,
  organisation: OrganizationEntity[] = [],
  institution: InstitutionEntity[] = [],
  sortTable: any,
  filters: { [key: string]: any | any[] }
): Promise<{ results: UserEntity[]; pageCount: number; totalItems: number }> {
  const url = `${process.env.REACT_APP_BASE_API_URL}/users?perPage=${perPage}&page=${page}`

  let listOfusers: UserEntity[] = []
  const params = new URLSearchParams()

  organisation.forEach((org) => {
    params.append('idOrganisation[]', org.id)
  })
  institution.forEach((insti) => {
    params.append('companyId[]', insti.id)
  })
  if (sortTable) {
    Object.keys(sortTable).forEach((key: string) => {
      params.append(`order[${labelsToApiFields[key]}]`, sortTable[key])
    })
  }

  flatFilter(params, filters)

  if (cancelToken !== undefined) {
    cancelToken.cancel('Operation canceled due to new request.')
  }

  cancelToken = axios.CancelToken.source()

  try {
    const resp = await axios({
      method: 'GET',
      url,
      params,
      cancelToken: cancelToken.token,
    })
    if (resp?.data) {
      // Result Normalization
      listOfusers = UsersNormalizer.normalize(getMemberData(resp.data))
      const totalItems = parseInt(resp.data['hydra:totalItems'], 10)
      return {
        results: [...listOfusers],
        pageCount: Math.ceil(totalItems / perPage),
        totalItems,
      }
    }
  } catch (err) {
    if (isCancel(err)) {
      return Promise.resolve({ pageCount: 0, results: err, totalItems: 0 })
    }
    throw new Error(`Erreur : ${err}`)
  }
  return Promise.resolve({ pageCount: 0, results: [], totalItems: 0 })
}

/* eslint no-param-reassign: ["error", { "props": false }] */
export function LinkCompanyToUser(
  users: UserEntity[],
  companies: InstitutionEntity[]
): UserEntity[] {
  const newusers = [...users]
  newusers.forEach((user) => {
    const entity = companies.find((company) => company.id === user.companyId)
    user.company = entity
  })
  return newusers
}

// patch user status
export async function patchUserStatus(
  userstatus: string,
  userId: string
): Promise<number> {
  const url = `${process.env.REACT_APP_BASE_API_URL}/users/${userId}`
  let status = 400
  try {
    const resp = await axios({
      method: 'PATCH',
      url,
      data: {
        state: userstatus,
      },
    })
    status = resp?.status
  } catch (err) {
    throw new Error(`Une erreur est survenue lors de l'enregistrement`)
  }
  return status
}

/**
 * Updates the application context using dispatch
 * This method is called in LoginForm, CheckUserForm and AutoRefreshToken
 */
export async function dispatchUserInformations(
  userInformations: any,
  dispatch: Dispatch<APP_ACTIONS>,
  state: InitialStateType
) {
  const {
    username,
    userId,
    isInternal,
    organizations,
    roleNames,
    firstname,
    backgroundColor,
    accessDemands,
    accessDocs,
  } = userInformations

  // dispatch username & userId
  dispatch({
    type: ActionTypes.SET_TOKEN_INFO,
    payload: {
      username,
      userId,
      isInternal,
      organizations,
      roleNames,
      firstname,
      accessDemands,
      accessDocs,
    },
  })

  if (backgroundColor) {
    dispatch({
      type: ActionTypes.SET_BACKGROUND_COLOR,
      payload: {
        backgroundColor,
      },
    })
  }

  if (state.allRoles.items.length === 0) {
    const allRoles = await getAllRoles()

    // dispatch all roles
    dispatch({
      type: ActionTypes.SET_ALL_ROLES,
      payload: {
        allRoles,
      },
    })
  }
}

export const getMSALToken = (email: string, force = false): Promise<any> => {
  const request = {
    scopes: [`${msalConfig.auth.clientId}/.default`],
    loginHint: email,
  }

  if (!force && msalInstance.getAccount() != null) {
    return msalInstance.acquireTokenSilent(request).catch((err) => {
      if (err.name === 'InteractionRequiredAuthError') {
        return getMSALToken(email, true)
      }

      return Promise.reject(err)
    })
  }

  msalInstance.loginRedirect(request)
  return msalInstance.acquireTokenSilent(request)
}

// resend creation mail
export async function sendCreationMail(userId: string): Promise<number> {
  const url = `${process.env.REACT_APP_BASE_API_URL}/users/${userId}/send_creation_mail`
  let status = 400
  try {
    const resp = await axios({
      method: 'GET',
      url,
    })
    status = resp?.status
  } catch (err) {
    throw new Error(`Erreur lors de l'envoi du mail de création`)
  }
  return status
}

export async function updateUserPerimeter(
  userId: string,
  initialPerimeter: string[],
  finalPerimeter: string[],
  history: any,
  userStatut: string
): Promise<number> {
  const result = -1
  const organisationsToAdd = finalPerimeter.filter(
    (value: string) => !initialPerimeter.includes(value)
  )

  const organisationsToDelete = initialPerimeter.filter(
    (value: string) => !finalPerimeter.includes(value)
  )
  if (organisationsToAdd.length > 0) {
    const { entities } = await searchOrganizationByIds(
      organisationsToAdd,
      history,
      true
    )
    const data = getFormatedOrganisations(entities)
    await patchUserForPerimetreOrganisations(
      data.filter((item) => {
        return finalPerimeter.includes(item.organisationId)
      }),
      userId,
      history
    )
  }

  if (organisationsToDelete.length > 0) {
    const res = await searchOrganizationByIds(
      organisationsToDelete,
      history,
      true
    )
    const datas = getFormatedOrganisations(res.entities).filter((elem) => {
      return !finalPerimeter.includes(elem.organisationId)
    })
    await deleteOrganisationsForUser(
      datas.filter((item) => {
        return initialPerimeter.includes(item.organisationId)
      }),
      userId,
      history
    )
  }

  if (userStatut === 'beingCreated') {
    await patchUserForActivation('activated', userId, history)
  }
  return result
}

export async function impersonnate(username: string): Promise<UserData> {
  const url = `${process.env.REACT_APP_BASE_API_URL}/impersonate`

  let impersonationToken = null

  const cookies = new Cookies()

  try {
    const resp = await axios({
      method: 'POST',
      url,
      /* headers: {
        'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
      }, */
      data: qs.stringify({
        client_id: `${process.env.REACT_APP_CLIENT_ID}`,
        client_secret: `${process.env.REACT_APP_CLIENT_SECRET}`,
        grant_type: 'impersonation_grant',
        username,
      }),
    })

    // IMPERSONATION TOKEN
    impersonationToken = resp?.data?.impersonation_token

    // REFRESH TOKEN
    const refreshTokentmp = resp?.data?.refresh_token

    // Mise en place du refresh_token et impersonation_token dans sessionStorage
    const decodedToken: DecodedToken = jwt_decode(impersonationToken)
    sessionStorage.setItem('refresh_token', refreshTokentmp)
    sessionStorage.setItem('impersonation_token', impersonationToken)

    if (
      !decodedToken.username &&
      !decodedToken.user_id &&
      !decodedToken.organizations
    ) {
      return Promise.resolve({
        username: '',
        userId: '',
        isInternal: false,
        organizations: [],
        roleNames: [],
        firstname: '',
        accessDemands: false,
        accessDocs: false,
      })
    }

    axios.defaults.headers.common = {
      Authorization: `Bearer ${impersonationToken}`,
    }

    const roles = decodedToken.roles.map((e) => e.name)

    return {
      username: decodedToken.username,
      userId: decodedToken.user_id,
      organizations: decodedToken.organizations.map((org) => org.id),
      roleNames: roles,
      firstname: decodedToken.first_name,
      accessDemands: Boolean(decodedToken.access_demands),
      accessDocs: Boolean(decodedToken.access_docs),
      isInternal: Boolean(decodedToken.isInternal),
    }
  } catch (err) {
    throw new Error(`Echec impersonnation.`)
  }
}

export async function GenerateExcelUsers(usersData: any[]) {
  fetch('/resources/ExportUtilisateurs.xlsx').then((response) => {
    response.arrayBuffer().then((buffer) => {
      const template = new XlsxTemplate(buffer)
      const expotedValues = {
        exportDate: moment(new Date()).format('DD/MM/YYYY'),
        exportedUser: usersData,
      }
      template.substitute(1, expotedValues)
      const result = template.generate({ type: 'arraybuffer' })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(new Blob([result]))
      const day = moment(new Date()).format('YYYY_MM_DD')
      const filename = `Export_Utilisateurs_${day}.xlsx`
      link.setAttribute('download', filename)
      document.body.appendChild(link)
      link.click()
    })
  })
}

export async function exportUsers(
  maxTotalLimit: number,
  allRoles: AllRolesEntity[]
): Promise<boolean> {
  try {
    let users: UserEntity[] = []
    let currentUrl = `${process.env.REACT_APP_BASE_API_URL}/users?perPage=${maxTotalLimit}&page=1&order[lastname]=asc`
    let hasMorePage = true

    while (hasMorePage) {
      const resp = await axios({
        method: 'GET',
        url: currentUrl,
      })

      const totalItems = parseInt(resp.data['hydra:totalItems'], 10)
      const entities = UsersNormalizer.normalize(getMemberData(resp?.data))
      users = [...users, ...entities]
      if (totalItems > users.length) {
        hasMorePage = !isLastPage(resp.data)
        currentUrl = `${process.env.REACT_APP_BASE_API_URL}${getNextPage(
          resp.data
        )}`
      } else {
        hasMorePage = false
      }
    }

    const dataToExport: any[] = users.map((user) => {
      return {
        firstname: user.firstname,
        lastname: user.lastname,
        username: user.username,
        domain: getDomainMail(user.username),
        createdAt: user.createdAt ? user.createdAt : '',
        lastConnexionDate: user.lastConnexionDate ? user.lastConnexionDate : '',
        role: getAdminUserSymbol(user.roles, allRoles, true),
        state: getStatusValue(user.state, StatusOptions),
        type: user.type === 'internal' ? 'Interne' : 'Externe',
        accessDocs: user.accessDocs ? 'Oui' : 'Non',
        accessDemands: user.accessDemands ? 'Oui' : 'Non',
      }
    })

    await GenerateExcelUsers(dataToExport)
    return true
  } catch (err) {
    throw new Error(`Erreur : ${err}`)
  }
}
