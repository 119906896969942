export interface KPIDemandsAPI {
  typeMotifDetail: string
  anneeMois: string
  typeRejeu: number
  succes: number
  echec: number
}

export interface KPIDemandsTranscoded {
  typeMotifDetail: string
  anneeMois: number
  typeRejeu: string
  succes: number
  echec: number
}

export const KPIDemandesMotif = [
  'Demande - Prestation - Collecte / Rotation',
  'Demande - Prestation - Arrêt Ponctuel de prestation',
  'Demande - Prestation - Arrêt Définitif de prestation',
  'Demande - Prestation - Dépôt',
]

export const KPIDemandesMotifLabels = [
  'Collecte',
  'ArretPonctuel',
  'ArretDefinitif',
  'Depot',
]

export const KPIDemandesRunLabels = ['FirstRun', 'Rerun1', 'Rerun2', 'Rerun3']
