enum COLORS {
  DEFAULT = '#fff',
  GRAY_0 = '#f4f8ff',
  LIGHTGREEN = '#9BCD41',
  DARKGREEN = '#AADC14',
  LIGHTGREENCLEAR = 'rgba(155, 205, 65, 0.2)',
  GREENCLEAR = '#E6F5BF',
  BLUE = '#D6EAFE',
  BLUECLEAR = 'rgba(23, 91, 167, 0.2)',
  BLUESKY = '#35a2eb80',
  DARKBLUE = '#030F40',
}

export const MATERIALS_SERVICES_COLORS: { [key: string]: string } = {
  '': '#9BCD41',
  A: '#77B7FC',
  A211: '#BBDCFD',
  A212: '#FCD6C3',
  A2XX: '#D4ED94',
  B: '#FFD469',
  C: '#F89E73',
  D: '#D6EAFE',
  E: '#8187A0',
  F: '#B4B7C6',
  G: '#FFE093',
  H: '#AADC14',
  I: '#E6F5BF',
  J: '#030F40',
  K: '#FFEDBE',
  L: '#FABA9B',
  M: '#FFC229',
  X: '#F67536',
  Z: '#C4E769',
  S: '#4F5779',
  NA: '#B4B7C6',
  '1': '#77B7FC',
  '2': '#8187A0',
  '3': '#030F40',
  '4': '#FFE093',
  '5': '#FFC229',
  '6': '#FFEDBE',
  '7': '#AADC14',
  '8': '#FABA9B',
  '9': '#F67536',
  '10': '#4F5779',
  '11': '#E2B2D6',
  '12': '#FABA9B',
  '13': '#F67536',
  '14': '#4F5779',
  '15': '#F5C4CC',
  AUT: '#B4B7C6',
  TRI: '#77B7FC',
  COI: '#F89E73',
  COM: '#AADC14',
  DEC: '#D6EAFE',
  DEP: '#D4ED94',
  EPA: '#F67536',
  INC: '#C4E769',
  MET: '#C4E769',
  STY: '#8187A0',
  PDR: '#A0CDFD',
  PTR: '#4F5779',
  SEP: '#FFD469',
  STO: '#030F40',
  TRA: '#BBDCFD',
}

export const STATUS_COLOR: { [key: string]: string } = {
  ALL: '#8187A0',
  ATTENTE_INFO_CLIENT: '#F67536',
  EN_COURS: '#77B7FC',
  PLANIFIE: '#77B7FC',
  TRAITE: '#AADC14',
  ANNULE: '#030F40',
}

export default COLORS
