/* eslint-disable @typescript-eslint/no-explicit-any */

import CollapseDto from './CollapseDto'
import { MATERIALS_SERVICES_COLORS } from './COLORS'

export function getEnumKeyByValue(myEnum: any, enumValue: any): any {
  const keys = Object.keys(myEnum).filter((x) => myEnum[x] === enumValue)
  return keys.length > 0 ? keys[0] : null
}

export function filterObjectWithoutProperty(
  objectToFilter: any,
  propertyNameToExclude: string
) {
  return Object.keys(objectToFilter)
    .filter((key) => {
      return key !== propertyNameToExclude
    })
    .reduce((obj, key) => {
      return {
        ...obj,
        [key]: objectToFilter[key],
      }
    }, {})
}

export function flatten(collapse: CollapseDto[]) {
  return collapse.reduce((acc: CollapseDto[], r: CollapseDto) => {
    if (r.children && r.children.length) {
      /* eslint-disable-next-line no-param-reassign */
      acc = acc.concat(flatten(r.children))
    } else {
      acc.push(r)
    }

    return acc
  }, [])
}

export function getColor(value: string) {
  return Object.keys(MATERIALS_SERVICES_COLORS).indexOf(value)
}
export function getDomainMail(mail: string) {
  return mail.substring(mail.lastIndexOf('@') + 1, mail.lastIndexOf('.'))
}

export function normalizeString(value: string | null) {
  return !value || value === 'NULL' || value === 'NA' ? '' : value
}
export function normalizeStringToNA(value: string | null) {
  return !value ||
    value === 'NULL' ||
    value === 'NA' ||
    value.trim() === '.' ||
    value.trim() === ''
    ? 'N/A'
    : value
}
export function truncateString(value: string, maxLength: number) {
  return value.length > maxLength
    ? `${value.substring(0, maxLength)}...`
    : value
}

export function createShape<T>(
  container: { [key: string]: T },
  propList: string[],
  initialPropValue: any
) {
  const returnContainer = { ...container }

  propList.forEach((prop: string) => {
    returnContainer[prop] = initialPropValue
  })

  return returnContainer
}
