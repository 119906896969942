import React from 'react'
import { useTranslation } from 'react-i18next'

import { Modal } from 'react-bootstrap'
import styled from 'styled-components'
import Button from '../components/Button'
import Alert from '../components/Alert'
import Loading from '../components/Loading'

interface Props {
  type: string
  title: string
  body: string
  backdrop?: 'static' | true | false
  show: boolean
  size?: 'sm' | 'lg' | 'xl'
  children?: React.ReactNode
  onHideShow: () => void
  onHideShowClassName?: string
  btnText?: string | null
  btnAction?: () => void
  isDisabledBtn?: boolean
  isLoadingBtn?: boolean
  secondBtnOnHideShow?: () => void
  secondBtnClassName?: string
  btnSecondaryText?: string | null
  defaultAction?: boolean
}

const PopUpInfo: React.FC<Props> = ({
  type,
  title,
  body,
  backdrop,
  show,
  children,
  onHideShow,
  onHideShowClassName,
  btnText,
  btnAction,
  size,
  secondBtnOnHideShow,
  secondBtnClassName,
  btnSecondaryText,
  defaultAction,
  isDisabledBtn,
  isLoadingBtn,
}: Props) => {
  const { t } = useTranslation()

  return (
    <>
      <Modal show={show} onHide={onHideShow} backdrop={backdrop} size={size}>
        <Modal.Header closeButton>
          <Modal.Title>
            <Alert type={type} alertMessaeg={title} />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {body}
          <Body>{children}</Body>
        </Modal.Body>
        <Modal.Footer>
          {secondBtnOnHideShow && (
            <Button
              className={secondBtnClassName}
              onClick={() => secondBtnOnHideShow()}
            >
              {btnSecondaryText !== null
                ? btnSecondaryText
                : t('modifyOrganization.modale.ok')}
            </Button>
          )}
          <Button
            className={onHideShowClassName}
            disabled={isDisabledBtn}
            onClick={
              btnAction && !defaultAction
                ? () => btnAction()
                : () => onHideShow()
            }
          >
            <Loading isLoading={isLoadingBtn} inButton>
              {btnText !== null ? btnText : t('modifyOrganization.modale.ok')}
            </Loading>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

PopUpInfo.defaultProps = {
  backdrop: undefined,
  children: null,
  size: undefined,
  onHideShowClassName: '',
  btnText: null,
  btnAction: () => undefined,
  secondBtnOnHideShow: undefined,
  secondBtnClassName: '',
  btnSecondaryText: null,
  defaultAction: true,
  isDisabledBtn: false,
  isLoadingBtn: false,
}

const Body = styled.div`
  padding: 1rem 3rem;
`

export default PopUpInfo
