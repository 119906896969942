/* eslint-disable no-unused-expressions */
/* eslint-disable no-await-in-loop */
/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios'
import CollapseDto from '../utils/CollapseDto'
import { formatToInputDateWDH } from '../utils/date-utils'

const INPUT_DATE_FORMAT_YEAR_MONTH = 'YYYY-MM'
export interface DataStructure {
  columns: string[]
  data: string[][]
}

interface DataType {
  [key: string]: string
}

export interface TableDto {
  dataIds: string[]
  data: DataType[]
  columnNames: string[]
}

export async function getWasteRegisters(
  startDate: Date,
  endDate: Date,
  listofIds: string[],
  sendRegisterByEmail = false
): Promise<any> {
  const expeditionDateBefore = `anneeMois[before]=${formatToInputDateWDH(
    endDate,
    INPUT_DATE_FORMAT_YEAR_MONTH
  )}`
  const expeditionDateAfter = `&anneeMois[after]=${formatToInputDateWDH(
    startDate,
    INPUT_DATE_FORMAT_YEAR_MONTH
  )}`

  let url = `${process.env.REACT_APP_BASE_API_URL}/registre_dechets?${expeditionDateBefore}${expeditionDateAfter}`
  listofIds.forEach((id) => {
    url += `&fkadresse.fkentreprise[]=${id}`
  })

  url += `&sendRegisterByEmail=${sendRegisterByEmail}`

  try {
    const resp = await axios.get(url)
    return resp.data
  } catch (err) {
    throw new Error(
      `Erreur lors de l'obtention du registre des déchet avec l'url: ${url}`
    )
  }
}

export function filterById(ids: string[], globallist: TableDto): TableDto {
  const result: TableDto = {
    data: [],
    dataIds: [],
    columnNames: globallist.columnNames,
  }
  ids.forEach((id) => {
    const index = globallist.dataIds.indexOf(id)
    if (globallist.data[index] !== undefined) {
      result.dataIds.push(id)
      result.data.push(globallist.data[index])
    }
  })
  return result
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function concatresult(child: any, parent: TableDto): TableDto {
  const original = parent
  if (child.data !== undefined || child.dataIds !== undefined) {
    original.data = original.data.concat(child.data)
    original.dataIds = original.dataIds.concat(child.dataIds)
    original.columnNames = child.columnNames
  }
  return original
}

export function prepareTableData(perimetrelist: CollapseDto[]): TableDto {
  const result: TableDto = { data: [], dataIds: [], columnNames: [] }
  for (let index = 0; index < perimetrelist.length; index += 1) {
    if (perimetrelist[index].children !== undefined) {
      perimetrelist[index].children?.forEach((child) => {
        if (child.type === 'table') {
          concatresult(child, result)
        } else {
          const tmpArray = [child]
          const sresult = prepareTableData(tmpArray)
          concatresult(sresult, result)
        }
      })
    }
  }

  return result
}

export async function downloadWasteRegister(
  filename: string
): Promise<{ status: number; data: string | null }> {
  const url = `${process.env.REACT_APP_BASE_API_URL}/registre_dechets/${filename}/file`

  try {
    const resp = await axios.get(url)
    return { status: resp.status, data: resp.data.file_base64 }
  } catch (err) {
    return { status: err.response.status, data: null }
  }
}
