enum ROUTES {
  Home = '/',
  CheckEmail = '/check-email',
  Login = '/login',
  OldUrls = '/url-change-info',
  CreatePassword = '/create-password',
  ResetPassword = '/reset-password',
  NewPassword = '/new-password',
  PasswordModified = '/password-modified',
  Dashboard = '/dashboard',
  NotFoundPage = '/notfound',
  DeniedAccess = '/denied-access',
  TechnicalError = '/technical-error',
  MaintenanceError = '/maintenance-error',
  ModifyUserEmail = '/modify-user-email',
  EmailModified = '/email-modified',
  EmailModifiedFail = '/email-modified-fail',
  Billing = '/billing',
  BillingDetail = '/billing/details/:id',
  BillingSynthesis = '/billing-synthesis',
  BillingAnalyseEvolution = '/billing-analyse-evolution',
  Profile = '/profile',
  ProfileOrganization = '/my-perimeter',
  SessionExpired = '/session-expired',
  WasteRegister = '/waste-register',
  ActivitySynthesis = '/activity-synthesis',
  ActivityAdvancedReports = '/activity-synthesis/advanced-reports',
  MonoAdvancedReport = '/activity-synthesis/advanced-report',
  ActivityAnalyseEvolution = '/activity-analyse-evolution',
  MyDocuments = '/my-documents',
  AttestationValorisation = '/attestation-valorisation',
  DocumentsManagement = '/documents-management',
  UserImpersonation = '/user-impersonation',
  AddDocument = '/documents-management/add-document',
  ModifyDocument = '/documents-management/modifiy-document/:id',
  ContactUs = '/contact-us',
  ContactSent = '/contact-sent',
  UserSignUp = '/sign-up',
  UserSignUpClient = '/sign-up-client',
  UserSignUpInfos = '/sign-up-infos',
  UserSignUpCreated = '/sign-up-user-created',
  MailPasswordSent = '/mail-password-sent',
  NotifSignUp = '/notif-sign-up',
  ExpandPerimeter = '/my-perimeter/expand-perimeter',
  ExpandPerimeterMultiple = '/my-perimeter/expand-perimeter-multiple-institution',
  Administration = '/administration',
  UsersManagement = '/administration/users-management',
  CreateUserProfile = '/administration/users-management/create-user-profile',
  AttachmentInstitution = '/administration/users-management/attachment-institution',
  AttachmentInstitutionDetail = '/administration/users-management/attachment-institution/:id',
  UserPerimeter = '/administration/users-management/user-perimeter',
  UserPerimeterDetail = '/administration/users-management/user-perimeter/:id',
  UpdateUserProfile = '/administration/users-management/modify-user-profile',
  UpdateUserProfileDetail = '/administration/users-management/modify-user-profile/:id',
  ModifyUserPerimeter = '/administration/users-management/modify-user-perimeter/',
  ModifyUserPerimeterDetail = '/administration/users-management/modify-user-perimeter/:idUser',
  UpdateUserAttachmentInstitution = '/administration/users-management/modify-user-institution',
  UpdateUserAttachmentInstitutionDetail = '/administration/users-management/modify-user-institution/:id',
  OrganisationsManagement = '/administration/organisations-management',
  CreateOrganization = '/administration/organisations-management/create-organization',
  ModifyOrganization = '/administration/organisations-management/modify-organization',
  ModifyOrganizationDetail = '/administration/organisations-management/modify-organization/:idOrganization',
  MessagesManagement = '/administration/MessagesManagement',
  CreateSideMessage = '/administration/MessagesManagement/CreateSideMessage',
  ModifySideMessage = '/administration/MessagesManagement/modify-side-message',
  ModifySideMessageDetail = '/administration/MessagesManagement/modify-side-message/:id',
  MyDemands = '/my-demands',
  DemandDetail = '/my-demands/details/:id',
  CreateDemand = '/my-demands/create-demand',
  MyServices = '/my-services',
  SuperAdmin = '/super-admin',
  SuperAdminKPI = '/super-admin/kpi',
  SuperAdminUsersManagement = '/super-admin/users-management',
  SuperAdminOrganisationsManagement = '/super-admin/organisations-management',
  SuperAdminCommunications = '/super-admin/communications',
  SuperAdminCommunicationsCreate = '/super-admin/communications/create',
  SuperAdminCommunicationsUpdate = '/super-admin/communications/update/:id',
  UploadRegister = '/upload-register',
  help = '/help',
}
// ROUTES accessible without a login
export const EXCLUDED_ROUTES: string[] = [
  ROUTES.CreatePassword,
  ROUTES.ResetPassword,
  ROUTES.NewPassword,
  ROUTES.PasswordModified,
  ROUTES.ContactSent,
  ROUTES.UserSignUpCreated,
  ROUTES.MailPasswordSent,
  ROUTES.OldUrls,
]

export const DASHBOARD_ROUTES: string[] = [
  ROUTES.Dashboard,
  ROUTES.CreateOrganization,
  ROUTES.ModifyOrganizationDetail,
  ROUTES.CreateUserProfile,
  ROUTES.UpdateUserProfileDetail,
  ROUTES.UpdateUserAttachmentInstitutionDetail,
  ROUTES.UsersManagement,
  ROUTES.UserPerimeterDetail,
  ROUTES.Profile,
  ROUTES.Billing,
  ROUTES.BillingDetail,
  ROUTES.BillingSynthesis,
  ROUTES.BillingAnalyseEvolution,
  ROUTES.AttachmentInstitutionDetail,
  ROUTES.ProfileOrganization,
  ROUTES.OrganisationsManagement,
  ROUTES.WasteRegister,
  ROUTES.ActivitySynthesis,
  ROUTES.ActivityAdvancedReports,
  ROUTES.MonoAdvancedReport,
  ROUTES.ActivityAnalyseEvolution,
  ROUTES.MyDocuments,
  ROUTES.DocumentsManagement,
  ROUTES.UserImpersonation,
  ROUTES.AddDocument,
  ROUTES.ModifyDocument,
  ROUTES.MessagesManagement,
  ROUTES.CreateSideMessage,
  ROUTES.ModifySideMessageDetail,
  ROUTES.ContactUs,
  ROUTES.ExpandPerimeter,
  ROUTES.ExpandPerimeterMultiple,
  ROUTES.Administration,
  ROUTES.MyDemands,
  ROUTES.DemandDetail,
  ROUTES.CreateDemand,
  ROUTES.ModifyUserPerimeterDetail,
  ROUTES.AttestationValorisation,
  ROUTES.MyServices,
  ROUTES.SuperAdmin,
  ROUTES.SuperAdminKPI,
  ROUTES.SuperAdminUsersManagement,
  ROUTES.SuperAdminOrganisationsManagement,
  ROUTES.SuperAdminCommunications,
  ROUTES.SuperAdminCommunicationsCreate,
  ROUTES.SuperAdminCommunicationsUpdate,
  ROUTES.help,
]

export const CREATE_USER_ROUTES: string[] = [
  ROUTES.CreateUserProfile,
  ROUTES.UpdateUserProfile,
  ROUTES.UpdateUserAttachmentInstitution,
  ROUTES.AttachmentInstitution,
  ROUTES.AttachmentInstitutionDetail,
  ROUTES.UserPerimeter,
  ROUTES.ModifyUserPerimeter,
  ROUTES.UserPerimeterDetail,
]

export const SIGNUP_USER_ROUTES: string[] = [
  ROUTES.UserSignUp,
  ROUTES.UserSignUpClient,
  ROUTES.UserSignUpInfos,
]

export const PATHS_WITHOUT_PERIMETER_FILTER: string[] = [
  ROUTES.ModifyOrganizationDetail,
  ROUTES.Profile,
  ROUTES.ProfileOrganization,
  ROUTES.ModifyDocument,
  ROUTES.ModifySideMessageDetail,
  ROUTES.MyServices,
  ...CREATE_USER_ROUTES,
  ...SIGNUP_USER_ROUTES,
]

// we filter in order to avoid repeat routes
export const WITH_PERIMETER_FILTER: string[] = DASHBOARD_ROUTES.filter(
  (pathname) => {
    return (
      !CREATE_USER_ROUTES.some((route) => pathname.includes(route)) &&
      !SIGNUP_USER_ROUTES.some((route) => pathname.includes(route)) &&
      !(ROUTES.ModifyUserPerimeter === pathname) &&
      !(ROUTES.Profile === pathname) &&
      !(ROUTES.ProfileOrganization === pathname) &&
      !(ROUTES.MyServices === pathname) &&
      !(ROUTES.ExpandPerimeter === pathname) &&
      !(ROUTES.ExpandPerimeterMultiple === pathname) &&
      !(ROUTES.ContactUs === pathname)
    )
  }
)

export default ROUTES
